/**
 * Created by r4zi4l on 24.06.2021
 */

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    DARK_TEXT_COLOR: new cc.Color(137, 64, 45, 255),
    GREEN: new cc.Color(111, 192, 38, 255),
    COLOR_RED: new cc.Color(243, 73, 64, 255),
    COLOR_BONUS: new cc.Color(159, 91, 244, 255),
    SHOP_STROKE_COLOR: new cc.Color(0, 0, 0, 100),
    SHOP_SHADOW_COLOR: new cc.Color(0, 0, 0, 80),
    TILE_REWARDS_COLOR: new cc.Color(9, 89, 151, 255),
    YELLOW_TEXT_COLOR: new cc.Color(247, 230, 43, 255),
    LIGHT_TEXT_DISABLED_COLOR: new cc.Color(200, 200, 200, 255),
    DARK_BLUE_TEXT_COLOR: new cc.Color(28, 69, 110, 255),
    ORANGE_BROWN_TEXT_COLOR: new cc.Color(159, 74, 29, 255),
    STICKERS_COLLECTION_COLOR: new cc.Color(160, 87, 96, 255),
    STICKER_LABEL_COLOR: new cc.Color(205, 126, 99, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    SHOP_STROKE: {
        color: cleverapps.styles.COLORS.SHOP_STROKE_COLOR,
        size: 1
    },

    SHOP_SHADOW: {
        color: cleverapps.styles.COLORS.SHOP_SHADOW_COLOR,
        direction: cc.size(0, -2),
        blur: 3
    },

    TITLE_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.TITLE_SHADOW,
        direction: cc.size(0, -2),
        blur: 1
    },

    LIGHT_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
        size: 1
    },

    LIGHT_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
        direction: cc.size(0, -3),
        blur: 3
    },

    GAME_MESSAGE_STROKE: {
        color: new cc.Color(102, 62, 30, 255),
        size: 2
    },

    GAME_MESSAGE_SHADOW: {
        color: new cc.Color(65, 26, 9, 210),
        direction: cc.size(0, -3),
        blur: 2
    },

    MISSION_STROKE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        size: 2
    },

    MISSION_SHADOW: {
        color: new cc.Color(0, 0, 0, 128),
        direction: cc.size(0, -4),
        blur: 5
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MENUBAR_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    MENUBAR_TEXT_DELTA: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    MENUBAR_TIMER_TEXT: {
        name: "nostroke",
        size: 32,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SIDEBAR_ICON_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },
    
    MENUBAR_LEVEL_TEXT: {
        size: 66
    },

    SUPPLIES_PAPER_TEXT: {
        size: 55,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUPPLY_REWARD_TEXT: {
        name: "default",
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUPPLIES_WINDOW_TEXT: {
        name: "nostroke",
        size: 47,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    SUPPLIES_DAYS_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MENUBAR_PROGRESS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    TILE_SHOP_OFFER_TEXT: {
        name: "default",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 45
    },

    TILE_SHOP_COINS_OFFER: {
        name: "default",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 60
    },

    SHOP_WINDOW_SMALL_TILE_TITLE: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        shadow: cleverapps.styles.DECORATORS.SHOP_SHADOW
    },

    SHOP_LIVES_PRODUCT_TILE_DESCRIPTION_TEXT: {
        name: "nostroke",
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    TILE_REWARDS: {
        size: 40,
        name: "nostroke",
        color: cleverapps.styles.COLORS.TILE_REWARDS_COLOR
    },

    TILE_REWARDS_HEADER: {
        size: 30,
        name: "nostroke",
        color: cleverapps.styles.COLORS.TILE_REWARDS_COLOR
    },

    SHOP_WINDOW_TILE_TITLE: {
        name: "default",
        size: 55,
        color: cleverapps.styles.COLORS.WHITE
    },

    PRODUCT_TILE_LIMIT_TEXT: {
        name: "nostroke",
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    DAILY_TASK_FONT: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    DAILY_TASK_NOTIFICATION_FONT: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    PACKWINDOW_TEXT: {
        name: "default",
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SHOP_WINDOW_LIVES_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        size: 40
    },

    TILE_SHOP_BADGE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TILE_SHOP_BADGE_STROKE
    },

    DAILY_TASK_REWARD_FONT: {
        name: "nostroke",
        size: 32,
        stroke: undefined,
        shadow: undefined,
        color: new cc.Color(83, 138, 26, 255)
    },

    TIMER_TEXT: {
        name: "nostroke",
        size: 40,
        stroke: undefined,
        shadow: undefined,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    DAILY_TASK_CTA: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    EXPEDITION_WINDOW_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    CHAT_REWARD_COUNT_TEXT: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    RATE_WINDOW_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    GROWTH_FUND_LEVEL_TITLE: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    GROWTH_FUND_LEVEL_TITLE_REACHED: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    GROWTH_FUND_LEVEL_REWARD: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_REWARD_REACHED: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.GREEN,
        stroke: undefined,
        shadow: undefined
    },

    GROWTH_FUND_LEVEL_VALUE: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_VALUE_REACHED: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.GREEN,
        stroke: undefined,
        shadow: undefined
    },

    DAILY_TASK_PROGRESS_FONT: {
        size: 30,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PACK_TILE_REWARDS_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    WINDOW_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    NOBG_WINDOW_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    NOBG_WINDOW_TITLE_VERTICAL_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    WINDOW_SMALL_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    TOURNAMENT_PLAYER_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.WHITE
    },
    WINDOW_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    EXTRA_WINDOW_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    LOADING_ANIMATION_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    SMALL_TEXT_ERROR: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_RED
    },
    FBCONNECT_WINDOW_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    TERMS_WINDOW_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    LIGHT_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    LIGHT_TITLE_TEXT_DISABLED: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UNIT_PROB: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    UNIT_AMOUNT: {
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    BUTTON_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    FORCE_MESSAGE_TEXT: {
        size: 39,
        color: cleverapps.styles.COLORS.ORANGE_BROWN_TEXT_COLOR
    },

    DIALOGUE_MESSAGE_TEXT: {
        size: 39,
        color: cleverapps.styles.COLORS.DARK_BLUE_TEXT_COLOR
    },

    LIGHT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DARK_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    LOADING_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    REWARDS_LIST_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    REWARD_WINDOW_REWARDS_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    GROWTH_FUND_CTA2: {
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_CTA1: {
        color: cleverapps.styles.COLORS.YELLOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_RECEIVE_AMOUNT: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    FRIEND_REQUEST_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLANS_ROW_NAME_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    CLAN_CHAT_PHRASE: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    CLAN_CHANGE_EMBLEM_TEXT: {
        size: 46,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SUBSCRIPTION_BUTTON_TITLE: {
        size: 22,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    SUBSCRIPTION_MAIN_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    TERMS_LINK_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    TERMS_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    VIPREWARDEDWINDOW_DESCRIPTION_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    VIPREWARDEDWINDOW_TIMER_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    VIPREWARDEDWINDOW_NOTE_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PROLONG_BUTTON_AMOUNT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    },

    DIALOGUE_PERSON_TITLE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    SHOP_USER_GOLD_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.ORANGE_BROWN_TEXT_COLOR
    },

    GAME_MESSAGE_TEXT: {
        size: 86,
        color: new cc.Color(130, 70, 40, 255)
    },

    MENUBAR_WORLD_TITLE_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    CHAIN_SALE_TIMER_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    TREASURE_SEARCH_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        size: 35
    },

    TREASURE_STAGE_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASKS_TIMER_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    NOADS_BONUS_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        size: 60
    },

    CHAIN_SALE_CLAIMED: {
        color: new cc.Color(11, 86, 214),
        shadow: cleverapps.styles.DECORATORS.CHAIN_SALE_CLAIMED_SHADOW
    },

    MISSION_MAIN_TITLE_TEXT: {
        size: 90,
        color: new cc.Color(255, 234, 0, 255),
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MISSION_TIMER_TEXT: {
        name: "nostroke",
        size: 42,
        color: new cc.Color(137, 64, 44, 255),
        stroke: undefined,
        shadow: undefined
    },
    SNAILFEAST_MISSION_MAIN_TITLE_TEXT: {
        size: 78,
        color: new cc.Color(255, 201, 39, 255),
        stroke: undefined,
        shadow: cleverapps.styles.DECORATORS.MISSION_SHADOW
    }
});

cleverapps.overrideStyles(cleverapps.styles.ToolbarItemView, {
    reward: {
        x: { align: "center", dx: 7 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarItem, {
    textOffset: {
        y: 3
    },

    icon: {
        x: 22,
        y: 3
    },

    bg: {
        height: 84,
        width: 289,
        minWidth: 247,
        padding: {
            bottom: 4
        }
    },

    attention: {
        x: { align: "right", dx: 2 },
        y: { align: "bottom", dy: -8 }
    },

    premiumSticker: {
        x: { align: "center", dx: 30 },
        y: { align: "center", dy: 63 },

        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 5 },
            width: 130
        }
    },

    timer: {
        icon: {
            y: { align: "center", dy: -2 }
        },

        text: {
            y: { align: "center", dy: -1 }
        }
    },

    plusButton: {
        offset: {
            x: -10,
            y: 8
        }
    },

    noPlusDx: 21
});

if (cleverapps.styles.MenuBarGameLevelView) {
    cleverapps.overrideStyles(cleverapps.styles.MenuBarGameLevelView, {
        margin: -16,

        level: {
            offset: {
                x: 28,
                y: 0
            }
        },

        value: {
            offset: {
                x: -1,
                y: 5,

                "collections": {
                    x: 1,
                    y: 2
                }
            }
        },

        crown: {
            offset: {
                x: 8,
                y: -18,

                "collections": {
                    x: 20,
                    y: -30
                }
            }
        },

        worldTitle: {
            position: {
                y: { align: "bottom", dy: -45 }
            }
        }
    });
}

if (cleverapps.styles.ProgressView) {
    cleverapps.overrideStyles(cleverapps.styles.ProgressView, {
        icon: {
            offset: {
                y: 6,
                x: 52
            },
            bg: {
                padding: {
                    x: 11,
                    y: 10
                },
                x: { align: "center" },
                y: { align: "center", dy: 1 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.CloseButton, {
    position: [{
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 15 }
    }, {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 20 }
    }, {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 20 }
    }]
});

cleverapps.overrideStyles(cleverapps.styles.Window, {
    border: {
        x: 25,
        top: 30,
        bottom: 30
    },

    padding: {
        x: 60,
        top: 70,
        bottom: 40
    },

    decors: {
        "decor_title_right": true,
        "decor_title_left": true,
        "window_notch_top": true,
        "window_notch_bottom": true,
        "window_notch_left": true,
        "window_notch_right": true,
        "window_liana_top_left": true,
        "window_liana_top_right": true,
        "window_liana_left": true,
        "window_liana_right": true,
        "window_liana_bottom_left": true,
        "window_liana_bottom_right": true
    },

    Background: {
        padding: {
            x: 0,
            y: 40
        }
    },

    HelpButton: {
        x: { align: "left", dx: 35 },
        y: { align: "top", dy: -45 },
        lovesTitle: true
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarIconView, {
    lock: {
        x: { align: "right", dx: 4 },
        y: { align: "bottom", dy: 39 }
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -15 },
        width: 160,
        height: 72,
        offsetY: 4,

        titleIcon: {
            x: { align: "left", dx: -53 },
            y: { align: "center", dy: 8 }
        }
    },

    mark: {
        x: { align: "right", dx: -2 },
        y: { align: "top", dy: -17 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    margin: 30
});

cleverapps.overrideStyles(cleverapps.styles.ShopWindow, {
    margin: { x: 80, y: 30 },
    smallTilesMargin: 20,

    horizontalMobile: {
        padding: { x: 0, top: 110, bottom: 85 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, {
    width: 460,
    badge: {
        x: { align: "right", dx: 34 },
        y: { align: "top", dy: -80 }
    },

    title: {
        width: 340,
        x: 0,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 4 }
        }
    },

    description: {
        width: 320,
        height: 120,
        bg: {
            width: 320,
            height: 100,
            y: { align: "center", dy: 0 },
            x: { align: "center", dx: 7 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    title: {
        margin: {
            y: 0
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CoinsProductTile, {
    width: 420,

    content: {
        y: { align: "bottom", dy: 70 },
        margin: 30
    },

    description: undefined,

    limit: {
        y: -60
    },

    decorators: [
        cleverapps.styles.Decorators["shop_marquise_coins"]
    ],

    background: {
        adjustBackgroundCapInsets: true
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackProductTile, {
    width: 420,
    height: 747,

    limit: {
        y: -40
    },

    content: {
        margin: 0,
        y: { align: "bottom", dy: 70 }
    },

    title: {
        width: 460,

        x: 8,
        y: 0,

        text: {
            y: { align: "center", dy: 6 }
        }
    },

    description: {
        y: 10,
        x: -7,

        bg: {
            width: 340,
            height: 145
        }
    },

    decorators: [
        cleverapps.styles.Decorators["shop_marquise_coins"]
    ]
});

cleverapps.overrideStyles(cleverapps.styles.TileRewardsBlock, {
    paddingX: 34,
    colMargin: 20,
    rowMargin: 10
});

cleverapps.overrideStyles(cleverapps.styles.TileButton, {
    x: { align: "center", dx: 0 },
    y: { align: "center", dy: 0 },
    width: 280,
    height: 104
});

cleverapps.overrideStyles(cleverapps.styles.TileIcon, {
    height: undefined,
    wrap: {
        dx: 15,
        dy: 0,
        height: 250
    },

    "starterPack": {
        wrap: { dx: 0, dy: 70 }
    },

    "starterPack0": {
        wrap: { dx: 0, dy: 70 }
    },

    "starterPack2": {
        wrap: { dx: 0, dy: 70 }
    },

    "subsMonth": {
        wrap: { dy: -15 }
    },

    "growthFund": {
        wrap: { dy: -40 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.TileBadge, {
    width: 115,
    x: { align: "center", dx: 2 },
    y: { align: "center", dy: 7 },
    text: {
        rotation: 12
    },
    rotation: 0
});

if (cleverapps.styles.LivesProductTile) {
    cleverapps.overrideStyles(cleverapps.styles.LivesProductTile, {
        width: 460,
        height: 747,

        background: {
            adjustBackgroundCapInsets: true
        },

        content: {
            y: { align: "bottom", dy: 60 },
            margin: 13
        },

        icon: {
            offsetY: -15
        },

        badge: {
            x: { align: "right", dx: 34 },
            y: { align: "top", dy: -80 }
        },

        description: {
            width: 310,
            height: 70,
            y: 3
        },

        limit: {
            y: -65
        },

        decorators: [
            cleverapps.styles.Decorators["shop_marquise_lives"]
        ]
    });
}

cleverapps.overrideStyles(cleverapps.styles.SoftCurrencyTile, {
    width: 420,
    content: {
        y: { align: "bottom", dy: 75 },
        margin: 30
    },

    limit: {
        y: -10
    },
    decorators: [
        cleverapps.styles.Decorators["shop_marquise_coins"]
    ]
});

cleverapps.overrideStyles(cleverapps.styles.OfferProductTile, {
    title: {
        width: 380
    },
    
    description: {
        height: 180,
        width: 380,
        y: 10,

        bg: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -5 },
            height: 180
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksWindow, {
    tabs: {
        height: 140,
        y: { align: "top", dy: -45 }
    },
    person: {
        x: { align: "center", dx: -1000 },
        y: { align: "bottom", dy: -20 }
    },

    window: {
        padding: {
            left: -20,
            right: -10
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksTab, {
    tasks: {
        margin: -3,
        padding: { x: 0, y: 0 }
    },

    margin: -2,

    timer: {
        padding: {
            top: 40,
            bottom: 30
        }
    },

    scrollBarPadding: {
        sidePadding: 0
    }
});

if (cleverapps.styles.AchievementsTab) {
    cleverapps.overrideStyles(cleverapps.styles.AchievementsTab, {
        scroll: {
            paddingY: 40
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DailyTaskView, {
    padding: {
        left: 10,
        right: 40,
        y: 0
    },

    title: {
        width: 330,

        wide: {
            width: 500,
            textWidth: 500
        }
    },

    titleAndRewards: {
        width: 320,

        wide: {
            width: 524
        }
    },

    icon: {
        bg: {
            width: 180,
            wide: {
                width: 250
            },

            height: 180
        }
    },

    progressBar: {
        height: 46,
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_dailytask,
            30: bundles.progress_bar.frames.bar_orange_dailytask,
            70: bundles.progress_bar.frames.bar_dailytask
        }
    },

    button: {
        width: 230
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI, {
    Avatar: {
        x: 10,
        top: 10,
        bottom: 10
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTaskTimer, {
    offsetY: 2,
    text: {
        width: 360
    }
});

cleverapps.overrideStyles(cleverapps.styles.Timer, {
    width: 230,
    height: 44
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksCompleteAllView, {
    padding: {
        top: 30
    },

    textLayout: {
        width: cleverapps.config.type === "merge" ? 620 : 360,
        margin: 20
    },

    prize: {
        x: { align: "right", dx: 28 },
        y: { align: "bottom", dy: -19 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PrizeBarComponent, {
    mark: {
        x: { align: "right", dx: 45 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.Tabs, {
    buttons: {
        width: 135,
        height: 240
    },

    attention: {
        x: { align: "left", dx: -20 },
        y: { align: "top", dy: -15 }
    },

    margin: 6
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionButtons, {
    buttonWidth: 410,
    buttonHeight: 120
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionTile, {
    width: 460,
    height: 747,

    content: {
        margin: 0,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 70 }
    },

    icon: {
        y: 0
    },

    rewardsMargin: {
        y: 0
    },

    description: {
        height: 145,
        width: 380,

        y: 8,

        rewardsMargin: {
            y: -2
        }
    },

    title: {
        width: 380,
        x: 0,
        y: 10,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 6 }
        }
    },

    decorators: [
        cleverapps.styles.Decorators["shop_marquise_prem"]
    ]
});

cleverapps.overrideStyles(cleverapps.styles.GrowthFundTile, {
    content: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 70 },
        margin: 0
    },

    icon: {
        y: 20
    },

    description: {
        height: 146,
        y: 8
    },

    title: {
        width: 380,
        x: 0,
        y: 10,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 6 }
        }
    },

    decorators: [
        cleverapps.styles.Decorators["shop_marquise_prem"]
    ]
});
if (cleverapps.styles.CookieJarView) {
    cleverapps.overrideStyles(cleverapps.styles.CookieJarView, {
        scale: 0.6,
        prizeFlyPositionShift: {
            y: 40,
            x: -50
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.GrowthFundWindow, {
    margin: 0,

    window: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    },

    top: {
        width: 1000,
        height: 290,

        text: {
            anchor: { x: 0.5, y: 0.5 },
            x: { align: "center", dx: 165 },
            y: { align: "center", dy: 30 }
        },

        icon: {
            x: { align: "center", dx: -135 },
            y: { align: "center", dy: -10 }
        },

        button: {
            x: { align: "right", dx: -53 },
            y: { align: "bottom", dy: 17 }
        },

        mark: {
            x: { align: "right", dx: -140 },
            y: { align: "bottom", dy: 20 }
        }
    },

    scroll: {
        margin: 0,
        width: 1000,
        height: 710,

        top: undefined
    }
});

cleverapps.overrideStyles(cleverapps.styles.GrowthFundLevelView, {
    width: 1000,
    height: 200,

    iconBg: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 0 },
        width: 260,
        height: 200
    },

    title: {
        x: { align: "left", dx: 295 },
        y: { align: "center", dy: 25 }
    },

    icon: {
        x: { align: "left", dx: 55 },
        y: { align: "center", dy: 0 }
    },

    rewards: {
        x: { align: "left", dx: 295 },
        y: { align: "center", dy: -25 }
    }
});

if (cleverapps.styles.LandMarkUnit) {
    cleverapps.overrideStyles(cleverapps.styles.LandMarkUnit, {
        title: {
            y: {
                align: "center",
                dy: 95
            },

            width: 200
        },

        unit: {
            x: { align: "center" },
            y: {
                align: "center",
                dy: -15
            },

            width: 160,
            height: 160
        },

        minimal_dialogue: {
            scale: 0.6
        },

        unit_background: {
            width: 250,
            height: 260
        },

        checkmark: {
            x: {
                align: "right",
                dx: -15
            },
            y: {
                align: "bottom",
                dy: -10
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.CountDownView, {
    textOffsetX: -6
});

cleverapps.overrideStyles(cleverapps.styles.RowView, {
    width: 1130,
    height: 135,

    paddingX: 40,

    content: {
        y: { align: "center", dy: 2 },
        height: 104
    },

    place: {
        withBadge: {
            width: 80
        },

        width: 50
    },

    name: {
        x: { align: "left" }
    },

    prize: {
        width: 110,
        verticalWidth: 110
    },

    score: {
        content: {
            x: { align: "center" },
            y: { align: "center", dy: -2 }
        },
        width: 160,
        verticalWidth: 140,
        height: 74
    }
});

cleverapps.overrideStyles(cleverapps.styles.TableView, {
    margin: -6
});

if (cleverapps.styles.DailyCupView) {
    cleverapps.overrideStyles(cleverapps.styles.DailyCupView, {
        title: {
            width: 700,
            height: 122,

            text: {
                width: 500,

                y: { align: "center", dy: -20 }

            }
        }
    });
}

if (cleverapps.styles.BackgroundsWindow) {
    cleverapps.overrideStyles(cleverapps.styles.BackgroundsWindow, {
        lockWithName: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        name: {
            x: { align: "center" },
            y: { align: "center" },

            bg: {
                x: { align: "center" },
                y: { align: "top", dy: -10 }
            }
        },

        padding: {
            top: -40,
            bottom: -24,
            right: -30,
            left: -30
        },

        item: {
            bg: {
                padding: {
                    x: 10,
                    y: 12
                }
            }
        },

        tape: {
            text: {
                y: 54
            },
            withName: {
                x: { align: "right", dx: 11 }
            },
            x: { align: "right", dx: 11 },
            y: { align: "top", dy: -114 }
        }
    });
}

if (cleverapps.styles.StarsProgressBar) {
    cleverapps.overrideStyles(cleverapps.styles.StarsProgressBar, {
        bar: {
            offsetX: 6,
            dy: 5
        },

        barAnimation: {
            offsetX: -15
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.RateWindow, {
    width: 850,
    height: 300,

    text: {
        width: 820,
        height: 250
    }
});

if (cleverapps.styles.ExtraWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ExtraWindow, {
        window: {
            padding: {
                left: 30,
                top: 60,
                right: 30,
                bottom: 80
            },
            CloseButton: {
                x: { align: "right", dx: 20 },
                y: { align: "top", dy: 20 }
            },
            HelpButton: {
                x: {
                    align: "left",
                    dx: 63
                },
                y: {
                    align: "top",
                    dy: -75
                }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.GrowthFundBuyWindow, {
    badge: {
        x: { align: "right", dx: 40 },
        y: { align: "top", dy: -100 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FriendRequestView, {
    width: 580,
    height: 270,

    processButton: {
        x: 70,
        height: 70
    },

    Avatar: {
        Margin: {
            x: 60
        }
    },

    Icon: {
        x: 250,
        y: -30
    },

    close: {
        x: 16,
        y: 14
    }
});

if (cleverapps.styles.TravelBookWindow) {
    cleverapps.overrideStyles(cleverapps.styles.TravelBookWindow, {
        content: {
            padding: {
                x: 60
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.WindowTitle.Types, {
    bg_window: {
        scale: [0.7, 1, 1],
        background: {
            height: 121,
            padding: {
                x: 60
            }
        },
        text: {
            y: { align: "center", dy: 2 }
        },
        position: [{
            x: { align: "center" },
            y: { align: "top", anchor: false, dy: 8 }
        }, {
            x: { align: "center" },
            y: { align: "top", anchor: false, dy: 14 }
        }, {
            x: { align: "center" },
            y: { align: "top", anchor: false, dy: 14 }
        }],
        helpButton: {
            x: { align: "left", dx: 60 },
            y: { align: "center", dy: -2 },
            padding: {
                x: 30
            }
        }
    },

    nobg_window: {
        background: {
            padding: {
                x: 130
            }
        },
        text: {
            x: { align: "center" },
            y: { align: "center", dy: 8 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.NotificationView, {
    progress: {
        type: "blue"
    },

    padding: {
        x: 50,
        y: 50
    }
});

cleverapps.overrideStyles(cleverapps.styles.BandButton, {
    size: {
        width: 100,
        height: 176
    },

    position: {
        y: { align: "top", dy: 20 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "bottom", dy: 36 }
    }
});

if (cleverapps.styles.ExpeditionPageView) {
    cleverapps.overrideStyles(cleverapps.styles.ExpeditionPageView, {
        height: 580,

        message: {
            text: {
                x: { align: "center", dx: 14 },

                height: 80
            },

            icon: {
                width: 70,
                height: 70
            },

            width: 260,
            height: 90
        },

        image: {
            y: { align: "center", dy: 35 }
        },

        current: {
            y: { align: "bottom", dy: 35 }
        },

        title: {
            x: { align: "center" },
            y: { align: "top", dy: -40, anchor: false },

            bg: {
                width: 380,
                height: 83,

                x: { align: "center" },
                y: { align: "top", dy: 0 }
            }
        },

        scroll: {
            y: { align: "center", dy: 15 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.PassLastChanceWindow, {
    rewards: {
        text: {
            x: { align: "center", dx: -37 },
            y: { align: "center", dy: 36 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DeleteAccountWindow, {
    window: {
        padding: {
            left: 80,
            right: 80,
            top: 80,
            bottom: 70
        }
    },

    info: {
        padding: {
            x: 40,
            y: 30
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassBuyTicketWindow, {
    rewards: {
        icon: {
            dx: 1,
            dy: 5
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassBuyTicketWindow, {
    rewards: {
        icon: {
            dy: 5
        }
    }
});

if (cleverapps.styles.ComboBarView) {
    cleverapps.overrideStyles(cleverapps.styles.ComboBarView, {
        position: [{
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -124 }
        }, {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -134 }
        }, {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -164 }
        }],
        slot: {
            offset: {
                x: -6,
                y: -1
            },
            margin: 53
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.VkLotteryIcon, {
    positions: {
        "iconTimer": {
            x: { align: "left", dx: -33 },
            y: { align: "bottom", dy: 20 }
        }
    }
});

if (cleverapps.styles.ProlongationOfferView) {
    cleverapps.overrideStyles(cleverapps.styles.ProlongationOfferView, {
        label: {
            amount: {
                x: { align: "center", dx: 2 },
                y: { align: "center", dy: 2 }
            }
        },

        bonusLabel: {
            x: { align: "center", dx: 135 },
            y: { align: "center", dy: 73 }
        }
    });
}
if (cleverapps.styles.BoosterBeforeView) {
    cleverapps.overrideStyles(cleverapps.styles.BoosterBeforeView, {
        textAvailable: {
            y: { align: "bottom", dy: 3 },
            width: 145,

            bg: {
                y: { align: "bottom", dy: -15 },
                height: 60,
                rect: cleverapps.UI.Scale9Rect.TwoPixelXY
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DialogueView, {
    widthPadding: 30,
    arrow: {
        y: { align: "top", dy: 27 }
    },

    text: {
        padding: {
            fromWallX: 50,
            x: 70,
            y: 50
        },
        verticalPadding: {
            fromWallX: 20,
            x: 20,
            y: 30
        }
    },

    title: {
        y: { align: "top", dy: -10 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MinimalDialogue, {
    person: {
        margin: 30
    },

    text: {
        arrow: {
            left: {
                x: { align: "left", dx: -26 },
                y: { align: "center", dy: 0 }
            },
            right: {
                x: { align: "right", dx: 26 },
                y: { align: "center", dy: 0 }
            }
        },

        verticalPadding: {
            top: 15,
            bottom: 20,
            left: 20,
            right: 20
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DialoguePersonTitleView, {
    text: {
        y: { align: "center", dy: 5 },
        padding: {
            x: 50,
            y: 5
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.Tooltip, {
    content: {
        offset: {
            y: 0
        }
    }
});

if (cleverapps.styles.SuppliesWindow) {
    cleverapps.overrideStyles(cleverapps.styles.SuppliesWindow, {
        window: {
            padding: [
                {
                    left: 40,
                    right: 40,
                    top: 40,
                    bottom: 40
                },
                {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                },
                {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                }
            ]
        },

        size: [
            { width: 950, height: 1200 },
            { width: 1390, height: 650 },
            { width: 1390, height: 650 }
        ],

        paper: {
            positions: [
                { x: { align: "center" }, y: { align: "top", dy: -120 } },
                { x: { align: "left", dx: 40 }, y: { align: "center" } },
                { x: { align: "left", dx: 40 }, y: { align: "center" } }
            ]
        },

        text: {
            positions: [
                { x: { align: "center" }, y: { align: "top", dy: -15 } },
                { x: { align: "center", dx: 195 }, y: { align: "top", dy: -54 } },
                { x: { align: "center", dx: 195 }, y: { align: "top", dy: -54 } }
            ],
            width: 870
        },

        daysLayout: {
            positions: [
                { x: { align: "center", dx: -12 }, y: { align: "bottom", dy: 105 } },
                { x: { align: "center", dx: 195 }, y: { align: "center", dy: 5 } },
                { x: { align: "center", dx: 195 }, y: { align: "center", dy: 5 } }
            ],

            margin: {
                x: 10,
                y: 5
            }
        },

        textWithTimer: {
            positions: [
                { x: { align: "center" }, y: { align: "bottom", dy: 40 } },
                { x: { align: "center", dx: 200 }, y: { align: "bottom", dy: 54 } },
                { x: { align: "center", dx: 200 }, y: { align: "bottom", dy: 54 } }
            ]
        },

        strikethrough: {
            positions: [
                { x: { align: "center" }, y: { align: "bottom", dy: 40 } },
                { x: { align: "center", dx: 197 }, y: { align: "bottom", dy: 65 } },
                { x: { align: "center", dx: 197 }, y: { align: "bottom", dy: 65 } }
            ]
        },

        button: {
            positions: [
                { x: { align: "center" }, y: { align: "bottom", dy: -113 } },
                { x: { align: "center", dx: 180 }, y: { align: "bottom", dy: -85 } },
                { x: { align: "center", dx: 180 }, y: { align: "bottom", dy: -85 } }
            ]
        }
    });
}

if (cleverapps.styles.SupplyPaperView) {
    cleverapps.overrideStyles(cleverapps.styles.SupplyPaperView, {
        title: {
            text: {
                width: 200,
                height: 80,

                positions: [
                    { x: { align: "center" }, y: { align: "top", dy: 0 } },
                    { x: { align: "center" }, y: { align: "top", dy: 20 } },
                    { x: { align: "center" }, y: { align: "top", dy: 20 } }
                ]
            }
        },

        rewards: {
            positions: [
                { x: { align: "center", dx: 6 }, y: { align: "center", dy: -80 } },
                { x: { align: "center" }, y: { align: "center", dy: -75 } },
                { x: { align: "center" }, y: { align: "center", dy: -75 } }
            ],

            size: [
                { width: 400, height: 450 },
                { width: 340, height: 450 },
                { width: 340, height: 450 }
            ],

            margin: [
                { x: 110, y: 0 },
                { x: 50, y: 0 },
                { x: 50, y: 0 }
            ]
        },

        decor: false
    });
}

if (cleverapps.styles.SupplyDayView) {
    cleverapps.overrideStyles(cleverapps.styles.SupplyDayView, {
        width: 220,
        height: 165,

        title: {
            y: { align: "top", dy: 5 },
            lineBreak: true,
            lineHeight: 65
        },

        last: {
            width: 225,
            height: 335,

            title: {
                y: { align: "top", dy: 39 },
                lineBreak: true,
                lineHeight: 110
            }
        }
    });
}

if (cleverapps.styles.PromotionSaleWindow) {
    cleverapps.overrideStyles(cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE], {
        title: {
            x: { align: "center", dx: -2 },
            y: { align: "center", dy: 476 }
        },

        header: {
            y: { align: "top", dy: 25 }
        },

        lots: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -40 },

            marginX: 74
        }
    });

    cleverapps.overrideStyles(cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PERIODIC_PROMOTION_SALE], {
        title: {
            font: cleverapps.styles.FONTS.BONUSBG_TITLE,
            y: { align: "center", dy: 454 }
        },

        lots: {
            y: { align: "center", dy: 27 },
            marginX: 50
        },

        timer: {
            y: { align: "top", dy: -153 }
        }
    });
}

if (cleverapps.styles.PromotionSaleLot) {
    cleverapps.overrideStyles(cleverapps.styles.PromotionSaleLot[Offers.TYPES.PROMOTION_SALE], {
        width: 410,
        height: 572,

        reward: {
            width: 380,
            height: 440,

            margin: {
                x: 10,
                y: -20
            }
        },

        badge: {
            x: { align: "right", dx: 35 },
            y: { align: "top", dy: 10 }
        },

        button: {
            width: 250,
            height: 90,

            y: { align: "bottom", dy: 20 }
        }
    });

    cleverapps.overrideStyles(cleverapps.styles.PromotionSaleLot[Offers.TYPES.PERIODIC_PROMOTION_SALE], {
        width: 400,

        badge: {
            x: { align: "right", dx: 80 },
            rotation: 0
        },

        reward: {
            y: { align: "top", dy: -15 }
        },

        button: {
            width: 300,
            height: 100,
            y: { align: "bottom", dy: 30 }
        }
    });
}

if (cleverapps.styles.TreasureSearchWindow) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchWindow, {
        delay: 16,

        content: {
            y: { align: "center", dy: 20 }
        },

        timer: {
            positions: [{
                x: { align: "center", dx: -75 }, y: { align: "bottom", dy: 210 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -5 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -5 }
            }]
        }
    });
}

if (cleverapps.styles.TreasureSearchView) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchView, {
        size: [{
            width: 1000, height: 1522
        }, {
            width: 1730, height: 890
        }, {
            width: 1730, height: 890
        }],

        header: {
            photo: {
                "h": {
                    positions: [{
                        x: { align: "left", dx: 60 }, y: { align: "top", dy: 5 }
                    }, {
                        x: { align: "left", dx: 65 }, y: { align: "top", dy: 0 }
                    }, {
                        x: { align: "left", dx: 65 }, y: { align: "top", dy: 0 }
                    }],
                    zOrder: [1, 0, 0],
                    rotation: [-1, -5, -5]
                },
                "g": {
                    positions: [{
                        x: { align: "left", dx: 135 }, y: { align: "top", dy: -215 }
                    }, {
                        x: { align: "left", dx: -15 }, y: { align: "top", dy: -219 }
                    }, {
                        x: { align: "left", dx: -15 }, y: { align: "top", dy: -219 }
                    }],
                    zOrder: [0, 1, 1],
                    rotation: [5, 5, 5]
                },
                "j": {
                    positions: [{
                        x: { align: "right", dx: -125 }, y: { align: "top", dy: 0 }
                    }, {
                        x: { align: "left", dx: 65 }, y: { align: "top", dy: -439 }
                    }, {
                        x: { align: "left", dx: 65 }, y: { align: "top", dy: -439 }
                    }],
                    zOrder: [2, 2, 2],
                    rotation: [-5, -5, -5]
                },
                "k": {
                    positions: [{
                        x: { align: "right", dx: -40 }, y: { align: "top", dy: -210 }
                    }, {
                        x: { align: "left", dx: -15 }, y: { align: "top", dy: -649 }
                    }, {
                        x: { align: "left", dx: -15 }, y: { align: "top", dy: -649 }
                    }],
                    zOrder: [1, 3, 3],
                    rotation: [1, 5, 5]
                }
            }
        },

        goalBlock: {
            "k": {
                positions: [{
                    x: { align: "left", dx: 248 }, y: { align: "top", dy: -100 }
                }, {
                    x: { align: "left", dx: 252 }, y: { align: "top", dy: -100 }
                }, {
                    x: { align: "left", dx: 252 }, y: { align: "top", dy: -100 }
                }],
                zOrder: 0,
                rotation: -90,
                scale: 0.39,
                delay: 10
            },
            "g": {
                positions: [{
                    x: { align: "left", dx: 232 }, y: { align: "top", dy: -303 }
                }, {
                    x: { align: "left", dx: 85 }, y: { align: "top", dy: -306 }
                }, {
                    x: { align: "left", dx: 85 }, y: { align: "top", dy: -306 }
                }],
                zOrder: 1,
                scale: 0.86,
                delay: 10
            },
            "j": {
                positions: [{
                    x: { align: "right", dx: -235 }, y: { align: "top", dy: -105 }
                }, {
                    x: { align: "left", dx: 259 }, y: { align: "top", dy: -544 }
                }, {
                    x: { align: "left", dx: 259 }, y: { align: "top", dy: -544 }
                }],
                zOrder: 2,
                scale: 0.49,
                delay: 10
            },
            "h": {
                positions: [{
                    x: { align: "right", dx: -212 }, y: { align: "top", dy: -314 }
                }, {
                    x: { align: "left", dx: 105 }, y: { align: "top", dy: -747 }
                }, {
                    x: { align: "left", dx: 105 }, y: { align: "top", dy: -747 }
                }],
                zOrder: 3,
                scale: 0.48,
                delay: 10
            }
        },

        progress: {
            zOrder: 4,

            bg: {
                size: [{
                    width: 1030, height: 210
                }, {
                    width: 230, height: 977
                }, {
                    width: 230, height: 977
                }],

                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -40 }
                }, {
                    x: { align: "right", dx: 50 }, y: { align: "center", dy: 0 }
                }, {
                    x: { align: "right", dx: 50 }, y: { align: "center", dy: 0 }
                }]
            },

            progressBar: {
                width: 800,
                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: -20 }
                }, {
                    x: { align: "center", dx: 80 }, y: { align: "center", dy: -22 }
                }, {
                    x: { align: "center", dx: 80 }, y: { align: "center", dy: -22 }
                }]
            },

            chestBlock: {
                direction: [cleverapps.UI.HORIZONTAL, cleverapps.UI.VERTICAL, cleverapps.UI.VERTICAL],
                margins: {
                    vertical: 105,
                    horizontal: 80
                },
                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 15 }
                }, {
                    x: { align: "center", dx: -20 }, y: { align: "center", dy: 25 }
                }, {
                    x: { align: "center", dx: -20 }, y: { align: "center", dy: 25 }
                }],

                text: {
                    width: 140,
                    y: { align: "center", dy: -80 }
                }
            },

            dividers: {
                position: { x: { align: "center", dx: 80 }, y: { align: "bottom", dy: 132 } },
                offset: { dx: 0, dy: 178 }
            }
        },

        miningTool: {
            bg: {
                width: 160,
                height: 640,
                positions: [{
                    x: { align: "right", dx: 43 }, y: { align: "center", dy: -50 }
                }, {
                    x: { align: "right", dx: -230 }, y: { align: "center", dy: 90 }
                }, {
                    x: { align: "right", dx: -230 }, y: { align: "center", dy: 90 }
                }]
            },

            icon: {
                x: { align: "center", dx: -20 },
                y: { align: "center", dy: -55 }
            },

            amountBg: {
                width: 85,
                height: 53,
                x: { align: "center", dx: -50 },
                y: { align: "center", dy: -65 }
            }
        },

        ads: {
            positions: [{
                x: { align: "right", dx: 23 }, y: { align: "bottom", dy: 209 }
            }, {
                x: { align: "right", dx: -209 }, y: { align: "bottom", dy: 50 }
            }, {
                x: { align: "right", dx: -209 }, y: { align: "bottom", dy: 50 }
            }]
        },

        field: {
            size: [{
                width: 770, height: 770
            }, {
                width: 787, height: 787
            }, {
                width: 787, height: 787
            }],

            border: {
                padding: {
                    x: 60,
                    y: 60
                }
            }
        },

        scroll: {
            size: [{
                width: 880, height: 900
            }, {
                width: 1140, height: 978
            }, {
                width: 1140, height: 978
            }],

            positions: [{
                x: { align: "left", dx: -55 }, y: { align: "center", dy: -140 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 25 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 25 }
            }]
        },

        shadow: {
            size: [{
                width: 1050, height: 1650
            }, {
                width: 1840, height: 990
            }, {
                width: 1840, height: 990
            }],

            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 }
            }]
        }
    });
}

if (cleverapps.styles.TreasureSearchCellView) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchCellView, {
        width: 200,
        height: 200
    });
}

cleverapps.overrideStyles(cleverapps.styles.NoAdsWindow, {
    width: 840,
    height: 950,

    note: {
        y: { align: "top", dy: -5 }
    },

    gift: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 }
    },

    bonusnote: {
        x: { align: "center" },
        y: { align: "bottom", dy: 200 }
    },

    badge: {
        scale: 1.2,
        textRotation: 0,
        x: { align: "right", dx: 70 },
        y: { align: "top", dy: -186 }
    },

    window: {
        padding: {
            top: 70,
            bottom: 70,
            x: 65
        }
    }
});

if (cleverapps.styles.StickersBookWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersBookWindow, {
        size: [
            { width: 1000, height: 1300 },
            { width: 1560, height: 1100 },
            { width: 1560, height: 1100 }
        ],

        fg: undefined,
        grid: {
            margin: {
                x: 25,
                y: 10
            },
            padding: {
                left: 20,
                bottom: 20
            }
        },

        scroll: {
            size: [
                { width: 920, height: 1120 },
                { width: 1525, height: 900 },
                { width: 1525, height: 900 }
            ],
            position: [
                { x: { align: "center" }, y: { align: "center", dy: -84 } },
                { x: { align: "center", dx: 1 }, y: { align: "center", dy: -52 } },
                { x: { align: "center", dx: 1 }, y: { align: "center", dy: -72 } }
            ]
        },

        progressView: {
            position: [
                { x: { align: "center", dx: 50 }, y: { align: "top", dy: -76 } },
                { x: { align: "center", dx: 0 }, y: { align: "top", dy: -76 } },
                { x: { align: "center", dx: 0 }, y: { align: "top", dy: -76 } }
            ],

            joker: [
                { x: { align: "left", dx: -50 }, y: { align: "center" } },
                { x: { align: "left", dx: -20 }, y: { align: "center" } },
                { x: { align: "left", dx: -20 }, y: { align: "center" } }
            ],

            text: {
                dy: 0
            }
        },

        collectionsAmount: {
            position: [
                { x: { align: "left", dx: 50 }, y: { align: "top", dy: -65 } },
                { x: { align: "left", dx: 50 }, y: { align: "top", dy: -65 } },
                { x: { align: "left", dx: 50 }, y: { align: "top", dy: -65 } }
            ],
            bg: {
                height: 72
            },
            text: {
                width: 120
            }
        },

        stickersAmount: {
            position: [
                { x: { align: "left", dx: 248 }, y: { align: "top", dy: -63 } },
                { x: { align: "left", dx: 280 }, y: { align: "top", dy: -63 } },
                { x: { align: "left", dx: 280 }, y: { align: "top", dy: -63 } }
            ],
            bg: {
                height: 72
            },
            text: {
                width: 120
            }
        },

        jokers: {
            position: [
                { x: { align: "right", dx: -45 }, y: { align: "top", dy: -56 } },
                { x: { align: "right", dx: -62 }, y: { align: "top", dy: -56 } },
                { x: { align: "right", dx: -62 }, y: { align: "top", dy: -56 } }
            ],
            text: {
                width: 120
            }
        },

        jokerAnimation: {
            position: {
                x: { align: "center" },
                y: { align: "bottom", dy: -250 }
            }
        }
    });
}

if (cleverapps.styles.PackOfferComponent && cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK]) {
    cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK], {
        pack: {
            offsetY: 420,
            offsetX: 90,
            badge: {
                x: { align: "right", dx: 30 },
                y: { align: "top", dy: 125 },
                rotation: 30
            }
        },
        tap: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -80 },
            rotation: 0,
            scaleX: 1
        }
    });
}

if (cleverapps.styles.StickersCollectionIcon) {
    cleverapps.overrideStyles(cleverapps.styles.StickersCollectionIcon, {
        textBg: undefined,

        title: {
            maxWidth: 210,
            maxHeight: 50,
            x: { align: "center", dx: 5 },
            y: { align: "bottom", dy: 33 },
            unavailableOpacity: undefined
        },

        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: 40 }
        },

        badge: {
            text: false,
            x: { align: "right", dx: 3 },
            y: { align: "top", dy: 15 }
        },

        prizebar: {
            checkmark: {
                y: { align: "center", dy: -1 }
            },
            button: {
                y: { align: "center", dy: -1 }
            },
            removeBarText: true,
            width: 180
        }
    });

    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        STICKERS_COLLECTION_TEXT: {
            size: 28,
            color: cleverapps.styles.COLORS.STICKERS_COLLECTION_COLOR
        },
        STICKERS_COLLECTION_UNAVAILABLE_TEXT: {
            size: 28,
            color: cleverapps.styles.COLORS.WHITE
        },
        COLLECTION_BADGE_TEXT: {
            size: 35
        },
        STICKERS_AVAILABLE_TEXT: {
            size: 40,
            color: cleverapps.styles.COLORS.STICKER_LABEL_COLOR
        }
    });
}

if (cleverapps.styles.StickersCollectionWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersComponent, {
        position: [
            { x: { align: "center" }, y: { align: "center", dy: -62 } },
            { x: { align: "center" }, y: { align: "center", dy: -62 } },
            { x: { align: "center" }, y: { align: "center", dy: -60 } }
        ],
        fg: {
            size: [
                { width: 900, height: 940 },
                { width: 900, height: 940 },
                { width: 1470, height: 650 }
            ]
        }
    });

    cleverapps.overrideStyles(cleverapps.styles.StickersCollectionWindow, {
        size: [
            { width: 1000, height: 1200 },
            { width: 1000, height: 1200 },
            { width: 1570, height: 850 }
        ],

        prize: {
            width: 400,
            position: [
                { x: { align: "center", dx: 0 }, y: { align: "top", dy: -64 } },
                { x: { align: "center", dx: 0 }, y: { align: "top", dy: -64 } },
                { x: { align: "center" }, y: { align: "top", dy: -45 } }
            ]
        },

        jokers: {
            position: [
                { x: { align: "right", dx: -49 }, y: { align: "top", dy: -54 } },
                { x: { align: "right", dx: -49 }, y: { align: "top", dy: -54 } },
                { x: { align: "right", dx: -56 }, y: { align: "top", dy: -33 } }
            ],
            hideOnComplete: true
        },

        text: {
            dy: 0
        },

        stamp: {
            x: { align: "right", dx: -35 },
            y: { align: "bottom", dy: 35 }
        },

        checkmark: undefined
    });

    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        STICKERS_PROGRESS_BAR_TEXT: {
            size: 30
        },
        STICKER_JOKER_BUTTON_TEXT: {
            size: 80,
            color: cleverapps.styles.COLORS.WHITE,
            stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
        }
    });
}

if (cleverapps.styles.StickerView) {
    cleverapps.overrideStyles(cleverapps.styles.StickerView, {
        label: {
            textBg: false,
            text: {
                x: { align: "center", dx: 0 },
                y: { align: "bottom", dy: 20 }
            },
            openedPosition: {
                x: { align: "center" },
                y: { align: "top", dy: -235 }
            }
        },
        image: {
            y: { align: "center", dy: 23 },
            zOrder: 11
        },
        hideLocked: true,
        frame: {
            zOrder: -1
        }
    });
    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        COLLECTION_WINDOW_TEXT: {
            color: cleverapps.styles.COLORS.STICKER_LABEL_COLOR
        }
    });
}

if (cleverapps.styles.StickersBookJokerView) {
    cleverapps.overrideStyles(cleverapps.styles.StickersBookJokerView, {
        bg: {
            width: 135,
            height: 72,
            x: { align: "right", dx: -40 },
            y: { align: "center" }
        },
        text: {
            x: { align: "center", dx: -20 },
            y: { align: "center" }
        },
        withBillet: true
    });
}

if (cleverapps.styles.StickersShopItemView) {
    cleverapps.overrideStyles(cleverapps.styles.StickersShopItemView, {
        rewards: {
            position: [{
                x: { align: "right", dx: -30 },
                y: { align: "center", dy: 5 }
            }, {
                x: { align: "center", dx: 0 },
                y: { align: "bottom", dy: 160 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "bottom", dy: 160 }
            }]
        },
        image: {
            position: [
                {
                    x: { align: "left", dx: 60 },
                    y: { align: "center", dy: 17 }
                },
                {
                    x: { align: "center", dx: 0 },
                    y: { align: "center", dy: 250 }
                },
                {
                    x: { align: "center", dx: 0 },
                    y: { align: "center", dy: 250 }
                }
            ]
        }
    });
}

if (cleverapps.styles.QuestIcon) {
    cleverapps.overrideStyles(cleverapps.styles.QuestIcon, {
        progress: {
            height: 60
        }
    });
}

if (cleverapps.styles.QuestInfoView) {
    cleverapps.overrideStyles(cleverapps.styles.QuestInfoView, {
        regular: {
            arrow: {
                x: { align: "left", dx: -24 }
            }
        },

        compact: {
            arrow: {
                x: { align: "left", dx: -22 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    height: 104
});

if (cleverapps.styles.HintButtonBooster) {
    cleverapps.overrideStyles(cleverapps.styles.HintButtonBooster, {
        width: 150,
        height: 120,

        content: {
            margin: -30,
            direction: cleverapps.UI.VERTICAL,
            layout: {
                y: { align: "center", dy: 30 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.LeadersWindow, {
    head: {
        rank: { x: 104 },
        player: { x: 290 },
        rating: { x: 1020 }
    }
});

if (cleverapps.styles.StickersRewardWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersRewardWindow, {
        stickers: {
            margin: {
                x: 80,
                y: 135
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.CrossPromoWindow, {
    window: {
        border: {
            x: 25,
            top: 25,
            bottom: 35
        },
        padding: {
            x: 20,
            top: 0,
            bottom: 0
        }
    },

    top: undefined
});

if (cleverapps.styles.TradeWindow) {
    cleverapps.overrideStyles(cleverapps.styles.TradeWindow, {
        window: {
            padding: {
                x: 60,
                top: 50,
                bottom: 55
            }
        }
    });
}

(function () {
    [
        cleverapps.styles.UI.Button.Images.button_green,
        cleverapps.styles.UI.Button.Images.button_blue,
        cleverapps.styles.UI.Button.Images.button_red,
        cleverapps.styles.UI.Button.Images.button_purple,
        cleverapps.styles.UI.Button.Images.small_button_blue,
        cleverapps.styles.UI.Button.Images.small_button_green
    ].forEach(function (obj) {
        cleverapps.overrideStyles(obj, {
            SKIP_RESOLUTION: {
                padding: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.05,
                    bottom: 0.12
                }
            }
        });
    });
}());

cleverapps.override(cleverapps.styles.SettingsWindow, {
    padding: {
        top: -10,
        x: -10
    },

    user: {
        padding: {
            top: 0,
            bottom: 10,
            left: 35
        }
    },

    avatar: {
        position: {
            x: { align: "left", dx: -50 },
            y: { align: "center" }
        },

        padding: {
            left: 30,
            right: 14,
            top: 10,
            bottom: 22
        }
    },

    name: {
        position: [{
            x: { align: "left", dx: 174 },
            y: { align: "center", dy: 35 }
        }, {
            x: { align: "left", dx: 200 },
            y: { align: "center", dy: 35 }
        }, {
            x: { align: "left", dx: 200 },
            y: { align: "center", dy: 35 }
        }]
    },

    id: {
        position: [{
            x: { align: "left", dx: 174 },
            y: { align: "center", dy: -30 }
        }, {
            x: { align: "left", dx: 200 },
            y: { align: "center", dy: -30 }
        }, {
            x: { align: "left", dx: 200 },
            y: { align: "center", dy: -30 }
        }]
    },

    button: {
        width: 560,
        height: 101
    },

    controlButton: {
        width: 130,
        height: 101
    }
});

(function () {
    [
        cleverapps.styles.UI.Button.Images.settings_control_button,
        cleverapps.styles.UI.Button.Images.settings_control_red_button
    ].forEach(function (obj) {
        cleverapps.overrideStyles(obj, {
            SKIP_RESOLUTION: {
                padding: {
                    top: 0.05,
                    bottom: 0.12
                }
            }
        });
    });
}());

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SETTINGS_WINDOW_NAME_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    SETTINGS_WINDOW_ID_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    SETTINGS_WINDOW_FOLLOW_US_TEXT: {
        name: "nostroke",
        size: 33,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    SETTINGS_WINDOW_VERSION_TEXT: {
        name: "nostroke",
        size: 25,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});