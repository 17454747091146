/**
 * Created by slava on 24.03.17.
 */

var HoseLocation = function (locationId) {
    BaseLocation.call(this, "hose", locationId);
};

HoseLocation.prototype = Object.create(BaseLocation.prototype);
HoseLocation.prototype.constructor = HoseLocation;

HoseLocation.prototype.load = function (stored) {
    this.progress = stored.progress || 0;
};

HoseLocation.prototype.getInfo = function () {
    return {
        progress: this.progress
    };
};

HoseLocation.prototype.withStars = function () {
    return true;
};

HoseLocation.prototype.isCompleted = function () {
    return this.progress >= HoseLocation.LEVELS_PER_LOCATION;
};

HoseLocation.prototype.getProgress = function () {
    return this.progress;
};

HoseLocation.prototype.gamePlayed = function () {
    if (!cleverapps.meta.isCompleted()) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);
    }

    this.incProgress();

    cleverapps.user.incLevel();
};

HoseLocation.prototype.incProgress = function () {
    this.progress += 1;
    this.save();
};

HoseLocation.prototype.getBackgroundBundle = function () {
    if (!bundles.main_background_vertical || !bundles.main_background_horizontal) {
        return;
    }
    return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "main_background_vertical" : "main_background_horizontal";
};

HoseLocation.GetMainScene = function () {
    return HoseScene;
};

HoseLocation.LEVELS_PER_LOCATION = 15;
