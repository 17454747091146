/**
 * Created by slava on 23.03.17.
 */

cleverapps.CurrentView = cc.Node.extend({
    avoidNode: "CurrentView",
    ctor: function (current) {
        this._super();
        this.letters = [];
        this.current = current;

        this.letterScale = cleverapps.styles.CurrentLetterView.scale || 1;

        var styles = cleverapps.styles.CurrentView;
        this.isRegularFont = styles.regularText !== undefined;

        this.setContentSize(styles.width, styles.height);
        this.setAnchorPoint(0.5, 0.5);

        this.lettersHolder = new cc.Node();
        this.lettersHolder.setAnchorPoint(0.5, 0.5);
        this.addChild(this.lettersHolder);
        this.lettersHolder.setPositionRound(styles.width / 2, this.height / 2);

        this.setCascadeOpacityEnabled(true);
        this.setCascadeColorEnabled(true);
        this.lettersHolder.setCascadeOpacityEnabled(true);
        this.lettersHolder.setCascadeColorEnabled(true);

        this.createBg();

        this.setLocalZOrder(styles.zOrder);

        current.on("wrong", this.runWrongAnimation.bind(this), this);
        current.on("right", this.runStatusAnimation.bind(this), this);
        current.on("push", this.push.bind(this), this);
        current.on("pop", this.pop.bind(this), this);
        current.on("reset", this.reset.bind(this), this);
        current.on("clear", this.clear.bind(this), this);
        current.onGetLetterView = this.createListener(this.getLetterAndMark.bind(this));

        this.setPositionRound(styles.position);

        if (styles.hideOffsetY) {
            this.setVisible(false);
        }
    },

    getLetterAndMark: function (letter) {
        var letterView = this.letters.find(function (current) {
            return current.char === letter.toUpperCase() && !current.isFlying();
        });

        if (!letterView) {
            return;
        }

        letterView.setFlying(true);
        return letterView;
    },

    adjustScale: function () {
        var baseScale = 1;
        var scale = cleverapps.resolution.getSceneSize().width / this.calcHolderWidth();
        if (scale < baseScale) {
            this.runAction(new cc.ScaleTo(0.1, scale));
        } else {
            this.lettersHolder.runAction(new cc.ScaleTo(0.1, baseScale));
        }
    },

    updateSize: function () {
        this.adjustScale();
    },

    createBg: function () {
        var styles = cleverapps.styles.CurrentView;

        if (!styles.bg) {
            return;
        }

        var image = bundles.game.frames.current_bg_png;
        if (cleverapps.isRumble() && cleverapps.knockoutGame.rumble.getCurrentRound().isLast() && bundles.game.frames.current_bg_hard_png) {
            image = bundles.game.frames.current_bg_hard_png;
        }
        if (this.current.game.level.isHard() && bundles.game.frames.current_bg_hard_png) {
            image = bundles.game.frames.current_bg_hard_png;
        }
        if ((this.current.game.level.isBonusRound() || this.current.game.level.isBonus()) && bundles.game.frames.current_bg_bonus_png) {
            image = bundles.game.frames.current_bg_bonus_png;
        }
        if (this.current.game.level.isTricky() && bundles.game.frames.current_bg_tricky_png) {
            image = bundles.game.frames.current_bg_tricky_png;
        }

        this.bg = new cc.Scale9Sprite(image);
        this.bg.setPositionRound(styles.width / 2, styles.bg.y);
        this.bg.setLocalZOrder(-1);
        this.addChild(this.bg);

        if (!styles.bg.resize) {
            this.bg.setContentSize2(styles.bg);
        }
        this.updateBgSize();
    },

    push: function (letter, silent) {
        letter = letter.toUpperCase();

        var styles = cleverapps.styles.CurrentView;

        if (this.isRegularFont) {
            this.letters.push(letter);
            if (!this.currentText) {
                this.currentText = cleverapps.UI.generateOnlyText("", cleverapps.styles.FONTS.CURRENT_TEXT);
                this.lettersHolder.addChild(this.currentText);
                this.currentText.setPositionRound(styles.regularText.position);
            }
            this.currentText.setString(this.letters.join(""));
        } else {
            var letterView = new CurrentLetterView(letter);
            this.lettersHolder.addChild(letterView);
            letterView.setPositionY(letterView.getPositionY());
            this.letters.push(letterView);
            letterView.setPositionX(this.calcLetterPositionX(this.letters.length - 1));
            if (!silent) {
                letterView.showUp();
                this.animateMoveX();
            } else {
                this.lettersHolder.setPositionX(this.width / 2 - this.calcLetterPositionX(this.letters.length - 1) / 2);
            }
        }

        this.updateBgSize();
    },

    pop: function () {
        if (this.isRegularFont) {
            this.currentText.setString(this.currentText.getString().slice(0, -1));
            this.letters.pop();
            this.animateMoveX();
            if (this.letters.length !== 0) {
                this.updateBgSize();
            }
        } else {
            var actions = [];
            if (!this.bg) {
                actions.push(new cc.Sequence(
                    new cc.FadeOut(0.2).easing(cc.easeIn(2)),
                    new cc.RemoveSelf()
                ));
            } else {
                actions.push(new cc.Sequence(
                    new cc.DelayTime(0),
                    new cc.RemoveSelf()
                ));
            }
            actions.push(new cc.CallFunc(function () {
                this.letters.pop();
                this.animateMoveX();
                if (this.letters.length !== 0) {
                    this.updateBgSize();
                }
            }.bind(this)));
            this.letters[this.letters.length - 1].runAction(new cc.Spawn(actions));
        }
    },

    updateBgSize: function () {
        var styles = cleverapps.styles.CurrentView;

        this.adjustScale();

        if (this.bg && styles.bg.resize) {
            if (this.letters.length > 0) {
                this.bg.setVisible(true);
                if (this.isRegularFont) {
                    this.bg.setContentSize2(this.currentText.width + styles.bg.padding.x, styles.bg.height);
                } else {
                    this.bg.setContentSize2((this.letters[0] ? this.letters[0].width * this.letterScale * this.letters.length : 0) + styles.bg.padding.x, styles.bg.height);
                }
            } else {
                this.bg.setVisible(false);
                this.bg.setContentSize2(0, 0);
            }
        }
    },

    reset: function (callback) {
        callback = callback || function () {};
        this.letters = [];
        this.lettersHolder.removeAllChildren();
        this.lettersHolder.setPositionX(this.width / 2);
        this.updateBgSize();
        this.stopAllActions();
        this.setScale(1);
        this.setOpacity(255);
        delete this.currentText;
        callback();
    },

    clear: function (callback) {
        this.stopAllActions();
        this.runAction(
            new cc.Sequence(
                this.animateHide(),
                new cc.CallFunc(function () {
                    this.reset(callback);
                }.bind(this))
            )
        );
    },

    animateHide: function () {
        var styles = cleverapps.styles.CurrentView;
        this.stopAllActions();
        if (styles.hideOffsetY) {
            return new cc.Sequence(
                new cc.MoveBy(0.25, 0, styles.hideOffsetY).easing(cc.easeBackIn(1)),
                new cc.Hide(),
                new cc.CallFunc(function () {
                    this.setPositionRound(styles.position);
                }.bind(this))
            );
        }

        return new cc.FadeOut(0.2).easing(cc.easeIn(2));
    },

    runIconsStatusAnimation: function (statusIcon) {
        if (statusIcon && this.letters.length > 0) {
            var icon = this.createStatusIcon(statusIcon);
            icon.setScale(0.4);
            icon.runAction(new cc.Spawn(
                new cc.FadeIn(0.07),
                new cc.ScaleTo(0.07, 1)
            ));
        }
    },

    runStatusAnimation: function (statusIcon, callback) {
        var scale = this.getScale();
        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.ScaleTo(0.2, scale * 1.2),
                new cc.ScaleTo(0.2, scale),
                this.animateHide(),
                new cc.ScaleTo(0.3, scale * 1.2),
                new cc.CallFunc(this.reset.bind(this, callback))
            )
        );
        this.runIconsStatusAnimation(statusIcon);
    },

    runWrongAnimation: function (statusIcon, callback) {
        this.stopAllActions();
        this.runAction(new cc.Sequence(
            StandartAnimations.shake(this, {
                power: 9,
                moveTime: 0.08,
                delayTimeAfterMove: 0,
                points: [
                    { x: 1, y: 0, r: 0 },
                    { x: -2, y: 0, r: 0 },
                    { x: 2, y: 0, r: 0 },
                    { x: -2, y: 0, r: 0 },
                    { x: 2, y: 0, r: 0 },
                    { x: -1, y: 0, r: 0 },
                    { x: 0, y: 0, r: 0 }
                ] 
            }),
            this.animateHide(),
            new cc.CallFunc(this.reset.bind(this, callback))
        ).setFinalize(function () {
            this.setPositionRound(cleverapps.styles.CurrentView.position);
        }.bind(this)));

        this.runIconsStatusAnimation(statusIcon);
    },

    createStatusIcon: function (statusIcon) {
        var styles = cleverapps.styles.CurrentView;
        var statusImage = new cc.Sprite(statusIcon);
        if (this.isRegularFont) {
            statusImage.setPositionX(this.currentText.width / 2 + styles.statusIcon.offsetX);
        } else {
            statusImage.setPositionX(this.calcLetterPositionX(this.letters.length) + styles.statusIcon.offsetX);
        }
        statusImage.setPositionY(statusImage.getPositionY());
        this.lettersHolder.addChild(statusImage);
        return statusImage;
    },

    calcLetterPositionX: function (letterId) {
        return this.letters[0] ? this.letters[0].width * this.letterScale * letterId : 0;
    },

    calcHolderWidth: function () {
        var styles = cleverapps.styles.CurrentView;
        return this.letters[0] ? this.letters[0].width * this.letterScale * this.letters.length + styles.indent : 1;
    },

    animateMoveX: function () {
        this.lettersHolder.stopAllActions();
        if (!this.isRegularFont) {
            this.lettersHolder.runAction(
                new cc.MoveTo(0.1, cc.p(this.width / 2 - this.calcLetterPositionX(this.letters.length - 1) / 2, this.height / 2))
            );
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CURRENT_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.CurrentView = {
    width: 500,
    height: 80,
    position: [
        { x: { align: "center" }, y: { align: "bottom", dy: 520 } },
        { x: { align: "center" }, y: { align: "bottom", dy: 525 } },
        { x: { align: "center" }, y: { align: "bottom", dy: 600 } }
    ],
    content: {
        x: 0,
        y: 0
    },
    statusIcon: {
        offsetX: 12
    },
    indent: 400,

    zOrder: 2
};